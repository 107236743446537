import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from "@/services/auth";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    // mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '',
            component: () => import('@/layouts/Main.vue'),
            children: [
                {
                    path: '/',
                    name: 'Home',
                    component: () => import('@/pages/documentos/rastrear/Rastrear.vue')
                },
                {
                    path: '/me',
                    name: 'Perfil',
                    component: () => import('@/pages/me/Perfil.vue')
                },
                {
                    path: '/rastrear/:id?',
                    name: 'RastrearDocumentos',
                    component: () => import('@/pages/documentos/rastrear/Rastrear.vue')
                },
                {
                    path: '/minhas',
                    name: 'MinhasEntregas',
                    component: () => import('@/pages/documentos/minhas/Minhas.vue')
                },
                {
                    path: '/documento/:id',
                    name: 'MinhasEntregas - Documento',
                    component: () => import('@/pages/documentos/minhas/Form.vue')
                },
                {
                    path: '/transportadoras',
                    name: 'Transportador',
                    component: () => import('@/pages/empresas/Lista.vue')
                },
                {
                    path: '/transportador/:id',
                    name: 'Transportador - Transportador',
                    component: () => import('@/pages/empresas/Form.vue')
                },
                {
                    path: '/usuarios',
                    name: 'Usuários',
                    component: () => import('@/pages/usuarios/Lista.vue')
                },
                {
                    path: '/usuario/:id',
                    name: 'Usuário',
                    component: () => import('@/pages/usuarios/Form.vue')
                },
                {
                      path: '/ocorrencias/modelos',
                      name: 'Modelos',
                      component: () => import('@/pages/ocorrencias/modelo/Lista.vue')
                  },
                  {
                      path: '/ocorrencias/modelo/:id',
                      name: 'Modelo',
                      component: () => import('@/pages/ocorrencias/modelo/Form.vue')
                  },
                
                {
                    path: '/ocorrencias',
                    name: 'Ocorrências',
                    component: () => import('@/pages/ocorrencias/tipo/Lista.vue')
                },
                {
                    path: '/ocorrencia/:id',
                    name: 'Ocorrência',
                    component: () => import('@/pages/ocorrencias/tipo/Form.vue')
                },
                {
                    path: '/situacoes',
                    name: 'Situações',
                    component: () => import('@/pages/situacoes/Lista.vue')
                },
                {
                    path: '/situacao/:id',
                    name: 'Situação',
                    component: () => import('@/pages/situacoes/Form.vue')
                },
            ]
          },
        {
          path: '',
          component: () => import('@/layouts/Full.vue'),
          children: [
              {
                path: '/login',
                name: 'Login',
                component: () => import('@/pages/main/login/Login.vue')
              },
              {
                  path: '/login/recupera',
                  name: 'Esqueci minha senha',
                  component: () => import('@/pages/main/login/Recupera.vue')
              },
              {
                  path: '/login/confirma',
                  name: 'Confirma nova senha',
                  component: () => import('@/pages/main/login/Confirma.vue')
              },
              {
                  path: '/error-404',
                  name: 'Erro 404',
                  component: () => import('@/pages/erro/Erro404.vue')
              },
              {
                  path: '/error-403',
                  name: 'Erro 403',
                  component: () => import('@/pages/erro/Erro403.vue')
              }
          ]
      },
      {
          path: '*',
          redirect: '/error-404'
      }
    ],
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

router.beforeEach((to, from, next) => {
    if (
        to.path === "/login" ||
        to.path === "/login/recupera" ||
        to.path === "/login/confirma" ||
        to.path === "/rastrear" ||
        to.path.startsWith("/rastrear/") ||
        to.path === "/error-404" ||
        to.path === "/error-403"
    ) {
        return next();
    }

    let authentication = auth.authentication();
    if (!authentication)
        router.push({ path: '/login', query: { to: to.path } });
        // router.push({ path: '/error-403', query: { to: to.path } });

    let perfil = auth.info().perfil;

    if (perfil != 'ADMIN') {

        if (to.path.startsWith("/empresa")) {
            router.push({ path: '/error-403', query: { to: to.path } });
        }
        
        else if (perfil != 'TRANSPORTADOR') {
            if (
                to.path.startsWith("/usuario") ||
                to.path.startsWith("/ocorrencia") ||
                to.path === "/situacoes" ||
                to.path.startsWith("/situacao")
            ) {
                router.push({ path: '/error-403', query: { to: to.path } });
            }
        }
    }

    return next();
});

function patchRouterMethod (router, methodName)
{
    router['old' + methodName] = router[methodName]
    router[methodName] = async function (location)
    {
        return router['old' + methodName](location).catch((error) =>
        {
            if (error.name === 'NavigationDuplicated')
            {
                return this.currentRoute
            }
            throw error
        })
    }
}

patchRouterMethod(router, 'push')
patchRouterMethod(router, 'replace')

export default router