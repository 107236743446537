import auth from '@/services/auth';
import messages from '@/services/messages';

export default {

  install(Vue) {
    Vue.prototype.$auth = auth;
    Vue.prototype.$msg = messages;
  }

};