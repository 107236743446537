export default {
	pt: {
		NotFound: 'Ops! Página não encontrada',
		Unauthorized: 'Não autorizado',
		Acessar: 'Acessar',
		SemCredencial: 'Não possuo credencial',
		Login: 'CNPJ/CPF/Identificador',
		Senha: 'Senha',
		Email: 'E-mail',
		LoginObrigatorio: 'Nome de usuário é necessário',
		SenhaObrigatoria: 'Senha é necessária',
		Sair: 'Sair',
		RastrearDocumentos: 'Rastrear documentos',
		MinhasEntregas: 'Minhas entregas',
		LoginError: 'CNPJ/CPF/Identificador não encontrado.',
		BuscaPorChave: 'por Chave NF-e',
        BuscaPorNumero: 'por número',
        BuscaPorRemetente: 'por remetente',
        BuscaPorDestinatario: 'por destinatário',
        BuscaPorPedido: 'por pedido',
		Buscar: 'Buscar',
		Remetente: 'Remetente',
		Destinatario: 'Destinatário',
		Chave: 'Chave',
		NumeroNF: 'Nº documento',
		DateEmissaoNF: 'Data de emissão documento',
		NumeroTransporte: 'Nº transporte',
		Data: 'Data',
		Ocorrencia: 'Ocorrência',
		Situacao: 'Situação',
		Editar: 'Editar',
		Adicionar: 'Adicionar',
		Documento: 'Documento',
		RemetenteCpfCnpj: 'Remetente CPF/CNPJ',
		DestinatarioCpfCnpj: 'Destinatário CPF/CNPJ',
		Salvar: 'Salvar',
		AdicionarOcorrencia: 'Nova ocorrência',
		Remover: 'Remover',
		Sim: 'Sim',
		Nao: 'Não',
		RemoveOcorrencia: 'Você confirma a remoção da ocorrência?',
		RemoveDocumento: 'Removendo documento',
		RemoveDocumentoConfirmacao: 'Tem certeza que deseja remover esse documento e todas as suas ocorrências?',
		Cancelar: 'Cancelar',
		NovaOcorrencia: 'Nova ocorrência',
		Sucesso: 'Sucesso',
		DataOcorrencia: 'Data da ocorrência',
		NenhumDocumentoEncontrado: 'Nenhum documento encontrado',
		CidadeInicio: 'Cidade inicio',
		CidadeFim: 'Cidade fim',
		DocumentosPorPagina: 'Documentos por página',
		EsqueciMinhaSenha: 'Esqueci minha senha',
		VoltaParaLogin: 'Voltar para login',
		NovaSenha: 'Digite seu identificador (CNPJ/CPF/Identificador) abaixo para receber uma nova senha em seu e-mail',
		Enviar: 'Enviar',
		ConfirmaNovaSenha: 'Clique no botão abaixo para confirmar a redefinição de uma nova senha para o e-mail:',
		Confirmar: 'Confirmar',
		Perfil: 'Perfil',
		SenhaAtual: 'Senha atual',
		TextoNovaSenha: 'Nova senha',
		TextoNovaSenha2: 'Confirmação de nova senha',
		SenhasNovasSaoDiferentes: 'Senhas novas são diferentes',
		Ocorrencias: 'Ocorrências',
		Situacoes: 'Situações',
		Usuarios: 'Usuários',
		Transportador: 'Transportador',
		RegistrosPorPagina: 'Registros por página',
		Nome: 'Nome',
		RemoveTransportador: 'Remover o registro do transportador',
		RemoveTransportadorConfirmacao: 'Tem certeza que deseja remover o registro do transportador, isso não pode ser desfeito',
		Usuario: 'Usuário',
		RemoveUsuario: 'Remover o registro do usuário',
		RemoveUsuarioConfirmacao: 'Tem certeza que deseja remover o registro do usuário, isso não pode ser desfeito',
		Ordem: 'Ordem',
		RemoveOcorrenciaConfirmacao: 'Tem certeza que deseja remover o registro de ocorrência, isso não pode ser desfeito',
		RemoveSituacao: 'Remover o registro de situação',
		RemoveSituacaoConfirmacao: 'Tem certeza que deseja remover o registro de situação, isso não pode ser desfeito',
		Visualizar: 'Visualizar',
		TipoOcorrencia: 'Tipo ocorrência',
		DescricaoOcorrencia: 'Ocorrência',
		Cor: 'Cor',
		CarregarLogo: 'Carregar nova logo',
		TrackingID: 'Cod. Pedido',
		DataInicio: 'Data inicio',
		DataFim: 'Data fim',
		ChaveApiUsuario: 'Chave para acesso pela API',
		Upload: 'Upload',
		Link: 'Link',
		Abrir: 'Abrir',
		Download: 'Download',
		OcorrenciasModelo: 'Modelo - Ocorrências',
		DataDoDia: 'Data do dia',
		HoraDoLancamento: 'Hora do lançamento',
		Hora: 'Hora',
		RemoveOcorrenciaModelo: 'Remover o modelo de ocorrência',
		RemoveOcorrenciaModeloConfirmacao: 'Tem certeza que deseja remover o registro de modelo de ocorrência, isso não pode ser desfeito',
		Fechar: 'Fechar',
		NrTransporte: 'Nr. transporte'
	}
}