import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './plugins/i18n'

import VueAxios from 'vue-axios';
import axios from './plugins/axios';
Vue.use(VueAxios, axios);

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast);

import ServicesPlugin from "@/plugins/services";
Vue.use(ServicesPlugin);

import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

Vue.use(require('vue-moment'));

import './assets/css/main.css'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');