const secretKey = 'DiejJhd8340dmwiYh2kdcushdmwF87564Dss#245%4Dc*jdamwerczxl@daksadf###$423mdsadfa21348445%4%';

class AuthService {

    signIn(req) {
      
      let token = req.data;
      localStorage.setItem('token', token.token);

      let data = {
        cpfCnpjIdent: token.cpfCnpjIdent,
        nome: token.nome,
        perfil: token.perfil,
        foto: token.foto
      };

      this.crypt(data);
    }

     info() {
      var cryptoJS = require("crypto-js");
      var bytes  = cryptoJS.AES.decrypt(localStorage.info, secretKey);
      var info = JSON.parse(bytes.toString(cryptoJS.enc.Utf8));
      return info;
    }

    crypt(data) {
      var cryptoJS = require("crypto-js");
      var cipherText = cryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
      localStorage.setItem('info', cipherText);
    }

    signOut() {
      localStorage.removeItem('token');
      localStorage.removeItem('info');
    }

    authentication() {
      const token = localStorage.getItem('token');
      if (!token)
        return false;

      try {
        if (typeof jwt_decode === 'undefined') {
          var jwt_decode = require('jwt-decode');
        }
        
        const { exp: expiration } = jwt_decode(token);
        const isExpired = !!expiration && Date.now() > expiration * 1000;

        if (isExpired)  // Se o token tem uma data de expiração e
          return false; // essa data é menor que a atual o usuário
                        // não está mais assinado.

        return true;
      } catch (e) {   // O "jwt-decode" lança erros pra tokens inválidos.
        return false; // Com um token inválido o usuário não está assinado.
      }
    }
    
}

export default new AuthService();
