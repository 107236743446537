import axios from 'axios'

const API_URL = window.API_URL;

const http = axios.create({
  baseURL: API_URL
});

http.interceptors.request.use((config) => {
  const token = localStorage.token;
  if (config.url.startsWith("/login"))
    return config;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config;
}, (err) => {
  return Promise.reject(err);
})

http.interceptors.response.use((response) => {
  return response;
}, (error) => {
  //if (error.response.status === 403) {
    // window.location = '/error-403'
  //}
  return Promise.reject(error);
})

export default http;